<template>
    <v-dialog v-model="inviteOps.dialog" max-width="500px">
        <v-card flat>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="10">
                        <span>Invite User</span>
                    </v-col>
                    <v-col cols="2">
                        <div class="float-right">
                            <CustomButton
                                btnIcon="mdi-close"
                                tooltipMsg="Close"
                                @click="hideDialog"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text class="px-4">
                <v-card flat>
                    <v-card-text>
                        <ValidationObserver ref="observer" v-slot="{}">
                            <v-form>
                                <v-row dense>
                                    <v-col cols="12">
                                        <CustomLabel
                                            for="emails"
                                            label="Email Addresses"
                                            required
                                        />
                                        <CustomTextAreaField
                                            id="emails"
                                            inputHint="* Use commas or semicolons to separate email addresses."
                                            inputPlaceholder="Gene Wilson <gene.wilson@acme.com>; Hannah Hunt <hannah.hunt@acme.com>, julie.smith@acme.com"
                                            inputRows="8"
                                            :inputValue="payload.emails"
                                            validationName="emails"
                                            validationRules="valid_email_addresses"
                                        />
                                    </v-col>
                                </v-row>

                                <v-row class="mt-4">
                                    <v-col>
                                        <CustomButton
                                            btnLabel="Cancel"
                                            tooltipMsg="Cancel"
                                            @click="hideDialog"
                                        />
                                        <CustomButton
                                            btnColour="primary"
                                            :btnIsLoading="inviteOps.isLoading"
                                            btnLabel="Save"
                                            tooltipMsg="Save"
                                            @click="inviteItems(payload)"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </ValidationObserver>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { addrs } from "email-addresses";

extend("valid_email_addresses", {
    getMessage: (field) => `The ${field} contains invalid email addresses.`,
    validate: (value) => {
        value == "abc";
    },
});

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    components: {},
    computed: {
        ...mapState("usersAdminMgmt", ["inviteOps"]),
    },
    data: () => ({
        payload: {},
    }),
    methods: {
        hideDialog() {
            this.$store.commit("usersAdminMgmt/HIDE_INVITE_OPS_DIALOG");
        },
        inviteItems() {
            this.$store.dispatch("usersAdminMgmt/inviteItems", {
                id: this.client.id,
            });
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("usersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("usersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
        validateEmailAddresses(emailAddresses) {
            let result = addrs.parseAddressList(emailAddresses);
            return result == null ? false : true;
        },
    },
    name: "DialogUserInvite",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
